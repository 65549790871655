import * as S from './styles';
import usePerformanceIndicators from '@/talons/performanceIndicators/usePerformanceIndicators';

import { RiCheckLine } from 'react-icons/ri';
import DragToReveal from '@/components/dragToRevealButton/DragToRevealButton';
import IndicatorsSlider from './IndicatorsSlider/indicatorsSlider';
import { useMemo, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const PerformanceIndicators = () => {
    const [opacity, setOpacity] = useState<number>(0);

    const {
        indicators,
        indicatorActiveIndex,
        setIndicatorActiveIndex
    } = usePerformanceIndicators();

    const messageToShow = useMemo(() => {
        return indicators[indicatorActiveIndex].title;
    }, [indicatorActiveIndex]);

    return (
        <S.Container>
            <S.Content>
                <S.Header>
                    <S.TitleButton>
                        Indicadores e desempenho
                    </S.TitleButton>
                    <S.Title>
                        {messageToShow}
                        {/* Aqui deve ser dinamico de acordo com os indicadores*/}
                    </S.Title>
                </S.Header>
                <S.SliderContainer>
                    <IndicatorsSlider indicators={indicators}
                        setIndicatorActiveIndex={setIndicatorActiveIndex}
                        indicatorActiveIndex={indicatorActiveIndex}
                    />
                </S.SliderContainer>
                <FinalStepComponent opacity={opacity} />
                {/* Só exibe o drag to reveal no último card */}
                {indicatorActiveIndex === indicators.length - 1 && (
                    <DragToReveal setOpacity={setOpacity} />
                )}
            </S.Content>
        </S.Container>
    );
};

const FinalStepComponent = ({ opacity }: any) => {

    const navigate = useNavigate();

    useEffect(() => {
        let redirectTimeout: any;

        if (opacity === 1) {
            redirectTimeout = setTimeout(() => {
                navigate('/dashboard'); // Redireciona para o dashboard após 10 segundos
            }, 10000); // 10000 ms = 10 segundos
        }

        return () => clearTimeout(redirectTimeout); // Limpa o timeout ao desmontar ou quando o opacity muda
    }, [opacity, navigate]);


    return (
        <S.FinalStepComponent opacity={opacity}>
            <S.ContentWrapper>
                <S.CheckSuccessIcon>
                    <RiCheckLine />
                </S.CheckSuccessIcon>
                <S.SuccessText>
                    Obrigado!
                </S.SuccessText>
                <S.SucessDescription>
                    Você será redirecionado para o seu dashboard..
                </S.SucessDescription>
            </S.ContentWrapper>

        </S.FinalStepComponent>
    )
};



export default PerformanceIndicators;
