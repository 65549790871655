import styled from "styled-components";
import { colors } from "@/constants";

export const Container = styled.div`
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto; /* Centraliza o slider */
`;

export const ItemCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;
  height: 340px;
  background-color: ${colors.brand.white};
  padding: 24px;
  border-radius: 16px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  text-align: center;
  color: ${colors.brand.grafite};

  position: relative;
`;

export const CardHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  font-size: 24px;
  font-weight: 600;
  line-height: 28.8px;
`;

export const CardIcon = styled.div`
    width: 70px;
    height: 70px;
    background-color: red;

    position: absolute;
    top: -25px;
    z-index: 2;
`;

export const CardTitle = styled.span`
  max-width: 240px;
  text-align: left;
`;

export const CardDescription = styled.p`
  width: 260px;
  text-align: left;

  font-size: 15px;
  font-weight: 400;
  line-height: 22.5px;

`;

export const CardStatus = styled.div<{ status: string }> `
  width: 100%;
  min-height: 110px;
  margin-top: 44px;
  padding: 20px;

  display: flex;
  flex-direction: column;
  gap: 8px;

  background-color: ${(props) => props.status === 'warning' ? colors.alert.red50 : colors.alert.green50};
  border-radius: 8px;

  text-align: left;
`;

export const Goal = styled.span`
  font-size: 32px;
  font-weight: 700;
  line-height: 37.5px;
`;

export const GoalMessage = styled.span`
  font-size: 12px;
  font-weight: 400;
  line-height: 14.06px;
  max-width: 50%;
`;

export const CardDetails = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 12px;
`;