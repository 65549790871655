import styled from "styled-components";
import { colors } from "@/constants";
import backgroundImage from '@/assets/svgs/Onboard.svg';

export const Container = styled.div`
    width: 100%;
    height: 100vh;
    background-color: ${colors.brand.grafite};
    background-image: url(${backgroundImage});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom;
    background-position-y: -50px;
    position: relative;
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: 100%;
    padding: 60px 24px;
`;

export const Header = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
`;

export const TitleButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    width: 177px;
    height: 22px;

    opacity: 50%;

    font-size: 12px;
    font-weight: 400;
    line-height: 14.06px;
    color: ${colors.brand.white};

    border: solid 1px ${colors.brand.white};
    border-radius: 100px;
`;

export const Title = styled.span`
    width: fit-content;
    max-width: 90%;
    height: 76px;
    font-size: 32px;
    font-weight: 600;
    line-height: 38.4px;
    color: ${colors.brand.white};
    white-space: normal; 
    word-wrap: break-word; 
`;

export const SliderContainer = styled.div`

`;

export const FinalStepComponent = styled.div<{ opacity: number }>`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    max-width: var(--mobile);
    height: 100%;
    background-color: ${colors.brand.grafite};
    opacity: ${props => props.opacity};

    z-index: ${props => props.opacity > 0.1 ? 10 : -1};

    display: flex;
    justify-content: center;
    align-items: flex-start;
`;

export const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    width: 100%;
    padding: 20px;
    margin-top: 176px;

    color: ${colors.brand.white};
`;

export const CheckSuccessIcon = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    
    width: 76px;
    height: 76px;
    background-color: ${colors.alert.green110};
    border-radius: 100px;

    svg{
        width: 56px;
        height: 56px;
        color: ${colors.brand.white};
    }
`;

export const SuccessText = styled.span`
    font-size: 40px;
    line-height: 46.88px;
    font-weight: 700;
`;

export const SucessDescription = styled.span`
    font-size: 16px;
    line-height: 18.75px;
    font-weight: 400;
`;