import styled from 'styled-components';
import { colors } from '@/constants';


export const DragContainer = styled.div`
  position: relative;
  padding: 12px;
  width: 100%;
  height: 60px;
  background-color: ${colors.grey7};
  border-radius: 18px;
  overflow: hidden;
  z-index: 10;
`;

export const DragButtonWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const DragProgress = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: #ff3b30;
  transition: width 0.1s ease-out;
`;

export const DragButton = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: ${colors.red};
  border: none;
  border-radius: 12px;
  width: 55px;
  height: 39px;
  cursor: pointer;

  svg{
    font-size: 24px;
    color: ${colors.brand.white};
  }
`;

export const DragMessage = styled.div`
  color: ${colors.brand.white};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 16px;
  font-weight: 600;
  line-height: 29px;
`;

export const RevealContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.3s ease-out; /* Transição suave para a opacidade */
`;

