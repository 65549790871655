import * as S from './styles';
import { useBackNavigation } from '@/shared';
import { SearchInput } from '@/components';
import { useCart, useGlobals } from '@/hooks';
import { LinkProps as Props } from '@/types';
import * as R from '@/constants/systemRoutes';
import { RiArrowLeftLine } from 'react-icons/ri';
import { useCallback, useEffect, useMemo } from 'react';
import { useParams, useLocation, UNSAFE_useRouteId } from 'react-router-dom';
interface PropsChild {
  rightIcon?: any;
  stickChild?: any;
  routeLabelSize?: number;
}

const Header = ({ rightIcon, stickChild, routeLabelSize }: PropsChild) => {
  const { goBack } = useBackNavigation();

  const { pathname } = useLocation();
  const routeID = UNSAFE_useRouteId();

  const params = Object.entries(useParams());
  const { routeTitle, fatherRouteTitle, setRouteTitle, setFatherRouteTitle } = useGlobals();
  const { isSearching, setFilterTerm, filterTerm } = useCart();

  useEffect(() => {
    if (routeID === R.SUBCATEGORIES.id) return;

    setRouteTitle('');
    setFatherRouteTitle('');
  }, [routeID, setRouteTitle, setFatherRouteTitle]);

  const listShowBackTitle = [R.SUBCATEGORIES];
  const listHasDescription = [R.SUBCATEGORIES];
  const listWithoutArrow = [R.LOGIN, R.MAIN, R.SUCCESS];
  const listHandleHookTitle = [R.SUBCATEGORIES, R.CATEGORY];
  const listWithouRadius = [R.PRODUCTIVITY_EXTRACT, R.SALES_CYCLE, R.SALES_REPORT];
  const listWithColeredExtension = [R.CATEGORY, R.PRODUCTIVITY_EXTRACT, R.SALES_CYCLE];
  const listWithExtension = [R.LOGIN, R.COMPANY, R.CATEGORY, R.CATEGORIES, R.SUBCATEGORIES, R.CLIENT_ORDER_DETAIL];
  const listToHideHeader = [R.SEARCH, R.ECOMMERCE, R.DASHBOARD, R.MY_CLIENTS, R.PRODUCT_PAGE, R.CLIENT_ORDER_PAGE, R.NEW_CLIENT, R.PERFORMANCE_INDICATORS, R.ORDER_STATUS_PAGE];

  const path = useMemo(() => {
    return params.reduce((acc, [k, v]) => `${acc?.replace(`/${v}`, `/:${k}`)}`, pathname);
  }, [params, pathname]);

  const findRoute = useCallback((list: Props[]) => list?.find((i) => [i.link, ...(i?.match || [])].includes(path)), [path]);

  const currentRouteLabel = useMemo(() => findRoute(Object.values(R))?.title || 'Página não encontrada', [findRoute]);

  console.log('Hey', findRoute(listToHideHeader), stickChild, rightIcon);

  if (findRoute(listToHideHeader) && !stickChild && !rightIcon) return null;

  const hasRadius = findRoute(listWithouRadius);
  const hideArrow = findRoute(listWithoutArrow);
  const hasExtension = findRoute(listWithExtension);
  const showBackTitle = findRoute(listShowBackTitle);
  const hasDescription = findRoute(listHasDescription);
  const coloredExtension = findRoute(listWithColeredExtension);
  const shouldShowHookTitle = findRoute(listHandleHookTitle);

  return (
    <S.Container>
      <S.HeaderWrapper
        $maxHeight={pathname === '/carrinho' && isSearching}
        $noradius={!!stickChild || !!hasRadius}
        $hasextension={!!hasExtension}
      >
        {isSearching && pathname === '/carrinho' ? (
          <SearchInput
            id={'carrinho'}
            setInput={setFilterTerm}
            value={filterTerm}
            onBlur={() => null}
            onFocus={() => null}
            name="Busca"
            placeholder="Pesquisar produto no carrinho"
          />
        ) : (
          <>
            {!hideArrow ? (
              <S.BackButton onClick={goBack} data-test="header-component-goback">
                <RiArrowLeftLine size={20} />
                {showBackTitle && shouldShowHookTitle && (routeTitle || fatherRouteTitle) && (
                  <S.RouteSublabel>{fatherRouteTitle || currentRouteLabel}</S.RouteSublabel>
                )}
              </S.BackButton>
            ) : (
              <S.BackButton />
            )}

            <S.RouteLabel $routelabelsize={routeLabelSize}>
              {(shouldShowHookTitle && routeTitle) || currentRouteLabel}
            </S.RouteLabel>

            {rightIcon || <S.ActionButton />}
          </>
        )}
      </S.HeaderWrapper>

      {hasExtension && <S.Extension $bgcolor={!!shouldShowHookTitle} $bgcolor2={!!coloredExtension} />}

      {shouldShowHookTitle && hasDescription && <S.Description>{routeTitle}</S.Description>}

      {stickChild && stickChild}
    </S.Container>
  );
};

export default Header;
