import React, { useState, useRef, useEffect, SetStateAction, Dispatch } from 'react';
import * as s from './styles'; // Importando os estilos

import dragArrow from '@/assets/svgs/dragArrow.svg'; // Importando a imagem da seta de arraste
import { RiCheckLine } from "react-icons/ri";

// Tipagem do evento que pode ser um mouse ou touch event
type DragEvent = React.MouseEvent<HTMLButtonElement> | React.TouchEvent<HTMLButtonElement>;

interface DragToRevealProps {
    setOpacity: Dispatch<SetStateAction<number>>;
}

const DragToReveal: React.FC<DragToRevealProps> = ({ setOpacity }) => {
    const [dragProgress, setDragProgress] = useState<number>(0);
    const containerRef = useRef<HTMLDivElement>(null);
    const buttonRef = useRef<HTMLButtonElement>(null);

    const handleMouseDown = (e: DragEvent) => {
        if (containerRef.current && buttonRef.current) {
            const containerWidth = containerRef.current.offsetWidth;
            const buttonWidth = buttonRef.current.offsetWidth;
            const startX = 'touches' in e ? e.touches[0].clientX : e.clientX;

            const handleMouseMove = (moveEvent: MouseEvent | TouchEvent) => {
                const currentX = 'touches' in moveEvent ? moveEvent.touches[0].clientX : moveEvent.clientX;
                let progress = ((currentX - startX) / (containerWidth - buttonWidth)) * 100;
                progress = Math.max(0, Math.min(100, progress)); // Limita o progresso entre 0% e 100%
                setDragProgress(progress);
            };

            // Função para terminar o arraste
            const handleMouseUp = () => {
                window.removeEventListener('mousemove', handleMouseMove);
                window.removeEventListener('mouseup', handleMouseUp);
                window.removeEventListener('touchmove', handleMouseMove);
                window.removeEventListener('touchend', handleMouseUp);

                if (dragProgress >= 100) {
                    // O usuário arrastou até o fim, então pode ser uma ação para desbloquear

                }
            };

            // Adiciona eventos de mouse ou toque
            window.addEventListener('mousemove', handleMouseMove);
            window.addEventListener('mouseup', handleMouseUp);
            window.addEventListener('touchmove', handleMouseMove);
            window.addEventListener('touchend', handleMouseUp);
        }
    };

    useEffect(() => {
        setOpacity(dragProgress / 100);
    }, [dragProgress]);

    return (
        <s.DragContainer ref={containerRef}>
        <s.DragButtonWrapper>
            <s.DragButton
                ref={buttonRef}
                onMouseDown={handleMouseDown}
                onTouchStart={handleMouseDown}
                style={{ left: `calc(${dragProgress}% - ${dragProgress * 0.55}px)` }}
            >
                {dragProgress < 100 ? (
                    <img src={dragArrow} alt="Arraste" />
                ) : (
                    <RiCheckLine />
                )}

            </s.DragButton>
            <s.DragMessage>{dragProgress < 100 ? "Arraste para entrar" : "Obrigado!"}</s.DragMessage>
        </s.DragButtonWrapper>
        </s.DragContainer >
    );
};

export default DragToReveal;
