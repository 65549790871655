import "./styles.css";
import { colors } from "@/constants";
import styled from "styled-components";
import { Link } from "react-router-dom";

export const ContentBanner = styled.div`
  padding: 0px 15px;
`;

export const LinkHref = styled.a``;

export const RouteLink = styled(Link)``;

export const Banner = styled.img`
  width: 100%;
  border-radius: 6px;
`;

export const Indicator = styled.img<{ isactive?: number }>`
  height: 10px;
  margin: 0px 4px;
  border: none;
  transition: all 0.25;
  border-radius: 10px;
  width: ${(props) => (props.isactive ? 20 : 10)}px;
  background-color: ${(props) =>
    props.isactive ? colors.brand.red : colors.gray.gray40};
`;

export const GalleryItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colors.brand.white};
  img {
    object-fit: contain;
    aspect-ratio: 1/1;
    width: 100%;
  }
`;

export const CustomNavigationController = styled.div<{ hasMarginTop: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: ${(props) => props.hasMarginTop ? 50 : 0}px;
`;

export const CustomDotsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
`;

export const CustomDot = styled.div<{ isactive?: number }>`
  width: ${(props) => props.isactive ? 8 : 4}px;
  height: ${(props) => props.isactive ? 8 : 4}px;
  border-radius: 50%;
  margin: 0 8px;
  background-color: ${colors.grey7};
  margin: 0 5px;
  cursor: pointer;
`;

export const CustomArrow = styled.div` 
  display: flex;
  gap: 8px;
`;

export const ArrowButton = styled.button < { disabled?: boolean } > `
  display: flex;
  justify-content: center;
  align-items: center;

  width: 56px;
  height: 56px;
  border-radius: 100%;
  border: none;
  background-color: ${colors.grey7};

  font-size: 24px;
  color: ${colors.brand.white};

  opacity: ${(props: any) => (props.disabled ? 0.2 : 1)}; ;
`;



