import { useState, useEffect } from 'react';

interface PerformanceIndicator {
    id: number;
    name: string;
    value: number;
}

const usePerformanceIndicators = () => {

    const indicators = [
        {
          id: 1,
          title: "Meta por Faturamento",
          status: "Você ainda não atingiu sua meta",
          description: "Parece que você ainda não atingiu a sua meta. ",
          percentage: 70,
          targetName: "Meta por Faturamento",
          icon: "chart-icon", // Isso pode ser uma classe CSS ou caminho para o ícone
          color: "#FDF7E9", // cor de fundo para o bloco de desempenho
          statusType: "warning", // Pode indicar que a meta não foi atingida
        },
        {
          id: 2,
          title: "Participação no Bazar",
          status: "Parabéns! Você está acima da meta.",
          description: "Você pode aumentar ainda mais sua participação.",
          percentage: 103,
          targetName: "Participação Acima da Meta",
          icon: "success-icon", // ícone indicando sucesso
          color: "#E6FAEB", // cor de fundo para o bloco de desempenho
          statusType: "success", // Meta foi atingida ou ultrapassada
        },
        {
          id: 3,
          title: "Meta da Categoria Pilar",
          status: "Você ainda não atingiu sua meta",
          description: "Parece que você ainda não atingiu a sua meta.",
          details: [
            {
              targetName: "Meta por Faturamento",
              percentage: 70,
              icon: "down-icon",
              color: "#FDF7E9",
              statusType: "warning",
            },
            {
              targetName: "Positivação",
              percentage: 70,
              icon: "down-icon",
              color: "#FDF7E9",
              statusType: "warning",
            }
          ]
        }
      ]; 

    //por enquanto não vamos implementar a lógica de carregamento e erro
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [indicatorActiveIndex, setIndicatorActiveIndex] = useState<number>(0);

    return {
        indicators,
        loading,
        error,
        indicatorActiveIndex,
        setIndicatorActiveIndex
    };
};

export default usePerformanceIndicators;