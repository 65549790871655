import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/effect-cards";
import "swiper/css/navigation";
import 'swiper/css/effect-creative';

import * as S from "./styles";
import { useState, useRef, useMemo, useEffect } from "react";
import { BannerProps } from "@/types";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay, EffectCards, Navigation, EffectCreative } from "swiper/modules";
import type { PaginationOptions } from "swiper/types/modules/pagination";
import { RiArrowRightLine, RiArrowLeftLine } from "react-icons/ri";

interface Props {
  slides?: BannerProps[] | any[];

  id: string;
  className?: "promotional" | "fastWay" | "";

  dots?: boolean;
  customDots?: boolean;
  activeIndex?: boolean;
  setActiveIndex?: any;
  isBanner?: boolean;
  isGallery?: boolean;
  autoplay?: boolean;
  hasMarginTop?: boolean;
  vertical?: boolean;
  canSwipe?: boolean;
  infinite?: boolean;

  marginBottom?: number;
  transitionDuration?: number;
  slidesToShow?: number | "auto";

  openFullScreen?: any;
  closeFullScreen?: any;
  effectCreative?: any;

  disableVerticalScroll?: () => {};
  showArrows?: boolean;
}

const Search = ({
  id,
  slides,

  dots = false,
  customDots = false,
  autoplay = false,
  isBanner = false,
  isGallery = false,
  infinite = false,
  hasMarginTop = true,
  canSwipe = false,
  vertical = false,

  className = "",
  marginBottom = 20,
  slidesToShow = 1,
  transitionDuration = 500,

  openFullScreen,
  showArrows = false,
  effectCreative = false,

  activeIndex,
  setActiveIndex
}: Props) => {
  const swiperRef = useRef<any>();

  const [isSwiperReady, setIsSwiperReady] = useState(false);
  const [isBeginning, setIsBeginning] = useState(true);
  const [isEnd, setIsEnd] = useState(false);

  const pagination = {
    enabled: dots && true,
    clickable: dots && true,
    renderBullet: (_, className) => `<span class="${className}"></span>`,
  } as PaginationOptions;

  const isValidHttpUrl = (str: string) => {
    let url;
    try {
      url = new URL(str);
    } catch (_) {
      return false;
    }
    return url.protocol === "http:" || url.protocol === "https:";
  };

  const updateSwiperState = (swiper: any) => {
    if(setActiveIndex){
      setActiveIndex(swiper.activeIndex);
    }
    setIsBeginning(swiper.isBeginning);
    setIsEnd(swiper.isEnd);
  };

  useEffect(() => {
    if (swiperRef.current) {
      setIsSwiperReady(true); // Atualiza para true quando o swiperRef está definido
    }
  }, [swiperRef.current]);

  // Função para renderizar os dots customizados
  const renderCustomDots = () => {
    if (!customDots || !swiperRef.current) return null;

    const totalSlides = swiperRef.current?.swiper.slides.length;
    return (
      <S.CustomDotsWrapper>
        {Array.from({ length: totalSlides }).map((_, index: any) => (
          <S.CustomDot
            key={`custom-dot-${index}`}
            isactive={activeIndex === index ? 1 : 0}
          
          />
        ))}
      </S.CustomDotsWrapper>
    );
  };

  const prepareComponents = slides?.map((slide, ind) => {
    if (isBanner) {
      const isExternal = isValidHttpUrl(slide?.LINK);
      return (
        <SwiperSlide key={`slider-${id}-${ind}`}>
          <S.ContentBanner>
            {isExternal ? (
              <S.LinkHref
                href={slide?.LINK}
                data-test={`component-slider-link-${slide.LABEL}`}
              >
                <S.Banner src={slide.IMAGE} alt={slide.LABEL} />
              </S.LinkHref>
            ) : (
              <S.RouteLink
                to={slide?.LINK}
                data-test={`component-slider-link-${slide.LABEL}`}
              >
                <S.Banner src={slide.IMAGE} alt={slide.LABEL} />
              </S.RouteLink>
            )}
          </S.ContentBanner>
        </SwiperSlide>
      );
    } else if (isGallery) {
      return (
        <SwiperSlide key={`slider-${id}-${ind}`}>
          <S.GalleryItem onClick={() => openFullScreen(slide.IMAGE)}>
            <img src={slide.IMAGE} alt={slide.LABEL} />
          </S.GalleryItem>
        </SwiperSlide>
      );
    } else {
      return <SwiperSlide key={`slider-${id}-${ind}`}>{slide}</SwiperSlide>;
    }
  });

  const effects = useMemo(() => {
    if (effectCreative) {
      return {
        effect: "creative",
        creativeEffect: {
          prev: {
            shadow: true,
            translate: [0, 0, -400],
          },
          next: {
            translate: ['100%', 0, 0],
          },
        },
      };
    }

  }, [effectCreative]);

  console.log('effects', effects);

  if (!prepareComponents?.length) return null;

  return (
    <div style={{ marginBottom }}>
      <Swiper
        ref={swiperRef}
        loop={infinite}
        autoplay={autoplay}
        className={className}
        pagination={pagination && !customDots ? pagination : false}
        allowTouchMove={canSwipe}
        speed={transitionDuration}
        slidesPerView={slidesToShow}
        modules={[Pagination, Autoplay, EffectCards, EffectCreative]}
        direction={vertical ? "vertical" : "horizontal"}
        onSlideChange={updateSwiperState}
        effect={effects ? effects?.effect : ''}
        creativeEffect={effects ? effects.creativeEffect : undefined}
      >
        {prepareComponents}
      </Swiper>
      <S.CustomNavigationController hasMarginTop={hasMarginTop}>
        {isSwiperReady && customDots && renderCustomDots()}
        {showArrows && (
          <S.CustomArrow>
            <S.ArrowButton onClick={() => swiperRef.current?.swiper.slidePrev()} disabled={isBeginning}>
              <RiArrowLeftLine />
            </S.ArrowButton>
            <S.ArrowButton onClick={() => swiperRef.current?.swiper.slideNext()} disabled={isEnd}>
              <RiArrowRightLine />
            </S.ArrowButton>
          </S.CustomArrow>
        )}
      </S.CustomNavigationController>
    </div>
  );
};

export default Search;
