import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import SignIn from "@/views/signIn/SignIn";
import * as R from "@/constants/systemRoutes";
import SplashScreen from "@/components/splash-screen/SplashScreen";

import { useUserContext } from "@/hooks";

const Main = () => {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const { isSignedIn } = useUserContext();

  useEffect(() => {
    if (isSignedIn) {
      setLoading(false);
      return;
    }

    const timer = setTimeout(() => {
      setLoading(false);
    }, 4000);

    return () => clearTimeout(timer);
  }, [isSignedIn]);

  useEffect(() => {
    if (!loading && isSignedIn) {
      navigate(R.DASHBOARD.link);
    }
  }, [loading, isSignedIn, navigate]);

  if (loading) {
    return <SplashScreen />;
  }

  return <SignIn />;
};

export default Main;
