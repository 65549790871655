import * as S from './styles';
import { Slider } from '@/components';

import { useMemo } from 'react';

const IndicatorsSlider = ({ indicators, setIndicatorActiveIndex, indicatorActiveIndex }: any) => {
    const cards = useMemo(() => {
        return indicators?.map((indicator: any) => {
            const details = indicator.details;

            return (
                <S.ItemCard key={indicator.id}>
                    <S.CardHeader>
                        <S.CardTitle>
                            {indicator.status}
                        </S.CardTitle>
                        <S.CardDescription>
                            {indicator.description}
                        </S.CardDescription>
                    </S.CardHeader>
                    <S.CardDetails>
                        {!details ? (
                            <S.CardStatus status={indicator.statusType}>
                                <S.Goal>{indicator.percentage}%</S.Goal>
                                <S.GoalMessage>Dos seus clientes compraram esse mês</S.GoalMessage>
                            </S.CardStatus>
                        ) : (
                            details.map((detail: any) => (
                                <S.CardStatus key={detail.targetName} status={detail.statusType}>
                                    <S.Goal>{detail.percentage}%</S.Goal>
                                    <S.GoalMessage>{detail.targetName}</S.GoalMessage>
                                </S.CardStatus>
                            ))
                        )}
                    </S.CardDetails>
                </S.ItemCard>
            )
        });
    }, [indicators]);

    return (
        <S.Container>
            <Slider
                id="indicators-slider"
                customDots
                canSwipe
                marginBottom={1}
                slides={cards}
                showArrows={true}
                activeIndex={indicatorActiveIndex}
                setActiveIndex={setIndicatorActiveIndex}
                effectCreative={true}
            />
        </S.Container>
    )

};

export default IndicatorsSlider;