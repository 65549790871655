import * as S from "./styles";

import { useGlobals } from "@/hooks";
import { ModalSync } from "@/components";
import { useCallback, useMemo, useState } from "react";

import versionHistory from "@/constants/version-history.json"
import ModalVersionHistory from "@/components/modal-version-history/ModalVersionHistory";

const Home = () => {
  const [isOpenModal, setModalState] = useState(false);

  const { lastSync, version, lastCheckedVersion, setLastCheckedVersion } = useGlobals();

  const showDot = useMemo(() => {
    return lastCheckedVersion !== version && version === versionHistory.version
  }, [lastCheckedVersion, version])

  const handleOpenVersionHistoryModal = useCallback(() => {
    setModalState(true)
    setLastCheckedVersion(version)
  }, [setModalState, setLastCheckedVersion, version]);

  return (
    <>
      <S.Container>
        <S.InfosContent>
          <S.MomentContent>
            <S.CalendarScheduleLine size={21} />

            <S.MomentData>
              <S.SyncLabel>Última sincronização</S.SyncLabel>
              <S.LastSync>{lastSync}</S.LastSync>
            </S.MomentData>
          </S.MomentContent>

          <S.Container>
            <ModalSync>
              <S.SyncButton>
                <S.SyncButtonIcon size={21} />
                <S.SyncButtonLabel>Sincronizar</S.SyncButtonLabel>
              </S.SyncButton>
            </ModalSync>
          </S.Container>
        </S.InfosContent>

        <S.VersionContent className="version-content">
          <S.LineVersion>
            <S.Version $redDot={showDot} onClick={() => {
              if (version !== versionHistory.version) {
                navigator.serviceWorker.getRegistrations().then(registrations => {
                  registrations.forEach(registration => {
                    registration.unregister().then(() => {
                      document.cookie = 'Clear-Site-Data: "cache", "cookies", "storage", "executionContexts"';
                      window.location.reload();
                    }).catch(error => {
                      console.error('Service worker unregistration failed:', error);
                    });
                  });
                }).catch(error => {
                  console.error('Service worker registration retrieval failed:', error);
                });
              } else {
                handleOpenVersionHistoryModal();
              }
            }}>Versão {version}</S.Version>
          </S.LineVersion>
        </S.VersionContent>
      </S.Container>

      <ModalVersionHistory isOpenModal={isOpenModal} setModalState={setModalState} />
    </>
  );
};

export default Home;
