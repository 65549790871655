import * as S from './styles';
import { colors } from '@/constants';
import { LinkPropsModal } from '@/types';
import { useCart, useClient } from '@/hooks';
import * as R from '@/constants/systemRoutes';
import { useLocation, useParams } from 'react-router-dom';
import { ModalListCompanies, ModalSync, CartCounter } from '@/components';

export type Types = {
  [key in LinkPropsModal]?: any;
};

const Menu = () => {
  const { pathname } = useLocation();
  const { totalQuantity } = useCart();
  const { selectedClient } = useClient();

  const params = Object.entries(useParams());
  const partialMenu = [R.DASHBOARD, R.CHOICE_CLIENT];
  const fullyMenu = [R.ECOMMERCE, R.CATEGORIES, R.CART, R.MY_ACCOUNT];
  const listToHide = [
    R.MAIN,
    R.LOGIN,
    R.COMPANY,
    R.CHECKOUT,
    R.USER_DATA,
    R.MY_CLIENTS,
    R.SALES_CYCLE,
    R.ORDER_STATUS_PAGE,
    R.PRODUCTIVITY_EXTRACT,
    R.INDICATORS,
    R.PERFORMANCE_INDICATORS,
    R.SALES_REPORT,
    R.NEW_CLIENT
  ];

  const isDashBoard = pathname.includes('dashboard');
  const isClientView = (selectedClient?.COD_CLIENTE && !isDashBoard) || !isDashBoard;
  const menu = isClientView ? fullyMenu : partialMenu;

  const path = params.reduce((acc, [k, v]) => `${acc?.replace(`/${v}`, `/:${k}`)}`, pathname);

  const Link = (item: any, ind: number) => {
    const matchs = [...(item?.match || []), item.link];
    const isActive = matchs.includes(path);

    if (!item.modal) {
      return (
        <S.NavLink
          key={`item-menu-${ind}`}
          to={item.modal ? '' : item.link}
          data-test={`menu-component-link-${item.id}`}
          color={isActive ? colors.brand.yellow : colors.brand.white}
        >
          {item.link === '/carrinho' && totalQuantity > 0 && <CartCounter cartTotalQuantity={totalQuantity} />}
          {isActive && <S.SelectedBar />}
          <S.Icon>{item.icon}</S.Icon>
          {item.title}
        </S.NavLink>
      );
    }

    const listModals: Types = {
      'modal-sync': ModalSync,
      'modal-companies': ModalListCompanies,
    };

    const Modal = listModals[item.modal as LinkPropsModal];
    return (
      <Modal key={`item-menu-${ind}`}>
        <S.ItemLink color={colors.brand.white}>
          <S.Icon>{item.icon}</S.Icon>
          {item.title}
        </S.ItemLink>
      </Modal>
    );
  };

  const shouldHide = listToHide.find(({ link }) => pathname === link);
  if (shouldHide) return null;

  return (
    <S.MenuContainer>
      <S.Content>{menu.map(Link)}</S.Content>
    </S.MenuContainer>
  );
};

export default Menu;
